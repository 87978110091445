@import "./modal";
@import "./modal_button";

.kr-smartform-wrapper.kr-type-embedded {
  @import "./card_header";
  @import "./methods_list";
}

.kr-smartform-wrapper.kr-type-popin {
  z-index: 0;

  @import "../popin/smartform/methods_list";
}
