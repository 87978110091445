// Card icons
.kr-card-icons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;

  span {
    display: inline-block;
    margin: 0;
    cursor: default;

    &.kr-hideout {
      position: fixed;
      top: -9999px;
      left: -9999px;
    }

    :not(.kr-extra-brands) {
      background-color: #fff;
    }

    &.kr-extra-brands {
      display: flex;
      position: relative;
      flex-direction: column;
      justify-content: center;
      color: #777;

      @import "./card_tooltip";
    }
  }

  span,
  span svg {
    box-sizing: content-box;
    height: 16px;
    width: 20px;
    vertical-align: baseline;
  }
}
