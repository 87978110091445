@font-face {
    font-family: BrandingSF;
    font-weight: 500;
    font-display: swap;
    src: local("BrandingSF-Medium"), url("./fonts/BrandingSF-Medium.woff") format("woff");
}

@font-face {
    font-family: BrandingSF;
    font-weight: 800;
    font-display: swap;
    src: local("BrandingSF-Black"), url("./fonts/BrandingSF-Black.woff") format("woff");
}

@font-face {
    font-family: BrandingSF;
    font-weight: 700;
    font-display: swap;
    src: local("BrandingSF-Bold"), url("./fonts/BrandingSF-Bold.woff") format("woff");
}

@font-face {
    font-family: BrandingSF;
    font-weight: 600;
    font-display: swap;
    src: local("BrandingSF-SemiBold"), url("./fonts/BrandingSF-SemiBold.woff") format("woff");
}

@font-face {
    font-family: BrandingSF;
    font-weight: 400;
    font-display: swap;
    src: local("BrandingSF-SemiLight"), url("./fonts/BrandingSF-SemiLight.woff") format("woff");
}

[data-theme="light"] {
    --content-bg-scann: #f2f2f2;
}

[data-theme="dark"] {
    --content-bg-scann: #161616;
}

.header_tyc {
    background-color: #10121f;
}

* {
    margin: 0;
    box-sizing: 0;
}

body {
    font-family: "BrandingSF", sans-serif
}

@media screen and (min-width: 1024px) {
    .card_promotion:hover .image_card {
        transition: all 0.5s ease-in-out;
        transform: scale(1.3);
    }
}

.text_card {
    position: absolute;
    bottom: -20px;
    left: 0;
    font-size: 200px;
    font-weight: 800;
    z-index: 1;
}

.hide-acord {
    display: none !important;
}

.show-acord {
    display: block !important;
}

.card_body_item {
    background-image: linear-gradient(#428050, #21244c);
}

.btn-evento:hover {
    background-color: var(--kt-content-bg-color);
}

.nav-noti {
    background-image: linear-gradient(45deg, #428050, #21244c);
}

.text_card .item_text_card {
    background-image: linear-gradient(45deg, #428050, #21244c);
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    opacity: .4;
}

.btn-gradiente {
    /* background-image: linear-gradient(45deg, #e58443, #e03339); */
    background-color: #21244c;
    color: #fff;
    font-weight: 700;
}

.btn-gradiente:hover {
    background-color: #3a3e73;
    color: #fff;
    font-weight: 700;
}

.image_card {
    /* transform: scale(1.1); */
    z-index: 10;
    filter: drop-shadow(-0.8rem 1rem 0.5rem rgba(0, 0, 0, 0.235));
}

.message_sidebar {
    display: none;
}

@media screen and (max-height: 660px) {
    #kt_aside_menu_wrapper {
        height: 548px !important;
    }
    .message_sidebar {
        display: flex;
    }
}


/* .card_table{
  border: 2px solid rgba(168, 167, 167, 0.609);
} */

.over_loading {
    opacity: .8 !important;
    /* background-color: #f5f5f539 !important; */
}

.css-l0diq4-control {
    border-radius: 6px !important;
}

@media screen and (min-width: 992px) {
    .aside-menu {
        margin-top: 50px !important;
    }
}

.content_scann {
    background-color: var(--content-bg-scann);
}

@media screen and (min-width: 992px) {
    .content_scann {
        padding: 30px 0;
        border-radius: 1.5rem;
    }
}

.content_card_report {
    width: 100%;
    /* max-width: calc(100vw - 100px); */
    max-width: 2200px;
    overflow-y: scroll;
    --kt-scrollbar-size: 8px;
}

.row_content {
    width: 2200px;
}

.date_title {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}

.header_title {
    height: 70px;
    /* padding: 1em; */
}

.second_row {
    height: 60px;
}

.three_row {
    height: 70px;
}

.btn_second{
    background-color: #1b1d36;
    color: white;
}
.btn_second:hover,
.btn_second:active,
.btn_second:focus-visible{
    background-color: #33365c !important;
    color: white !important;
}
.noti_active{
    background-color: rgba(255, 255, 255, 0.061);
    border-radius: 5px;
}
.card_noti:hover{
    background-color: rgba(255, 255, 255, 0.061);
    border-radius: 5px;
}

.font-15{
    font-size: 40px;
}