.kr-back-button {
  display: flex;
  align-items: center;
  position: relative;
  padding: 12px 15px;
  cursor: pointer;
  width: calc(100% - 30px);
  margin-bottom: 10px;

  &:hover,
  &.kr-focus {
    background-color: #f4f4f4;
  }

  label {
    cursor: pointer;
    color: $color-primary;
    text-transform: uppercase;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    margin-left: 35px;
  }

  .kr-arrow-left {
    display: flex;
    left: 14px;
    position: absolute;
    height: 100%;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    svg {
      fill: $color-primary;
      height: 20px;
      width: 20px;
    }
  }
}

// Styles with back button
.kr-smartform-modal-view--popin {
  .kr-method-content.kr-secure-iframe-visible .kr-back-button {
    margin-top: 0;
    margin-bottom: 5px;
  }

  // No back button - disable it
  .kr-method-content.kr-disabled-back-button .kr-back-button {
    display: flex;
    background-color: #f4f4f4;
    cursor: not-allowed;

    .kr-arrow-left svg {
      fill: #bbb;
    }

    label {
      color: #bbb;
      font-weight: 500;
      cursor: not-allowed;
    }
  }

  .kr-method-content.kr-hidden-back-button .kr-back-button {
    display: flex;
  }

  .kr-methods.kr-hidden-back-button {
    margin-top: -46px;
  }
}
