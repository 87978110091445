&.kr-wallet {
  padding-top: 90px + 60px + 5px;
}

.kr-wallet-tabs {
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;
}
