.kr-wallet-card-list {
  margin: 0;

  .kr-card-list-wrapper {
    border: 0;

    .kr-card-element .kr-card-label {
      margin-left: 24px;
    }
  }
}
