:root {
    --header-color: #181c32;
    --item-bg: rgba(255, 255, 255, 0.918);
    --p-color: #181c32;
    --title-head: #181c32;
}

[data-theme="light"] {
    --title-color-responsive: #181c32;
    --p-color-responsive: #ffffff;
    --item-bg-responsive: rgba(24, 28, 50, 0.818);
    --title-head: #181c32;
}

[data-theme="dark"] {
    --title-head: #ffffff;
    --title-color-responsive: #ffffff;
    --p-color-responsive: #181c32;
    --item-bg-responsive: rgba(255, 255, 255, 0.918);
    --shadow-card: 0 0.1rem 1rem 0.25rem rgb(255 255 255 / 5%) !important
}

// //SEGUNDO CARD
@media screen and (min-width: 1501px) {
    .col-card {
        flex: 0 0 auto;
        width: 25%;
    }
}

@media screen and (min-width: 1301px) and (max-width: 1500px) {
    .col-card {
        flex: 0 0 auto;
        width: 33.33333%;
    }
}

@media screen and (min-width: 649px) and (max-width: 1300px) {
    .col-card {
        flex: 0 0 auto;
        width: 50%;
    }
}

@media screen and (max-width: 648px) {
    .col-card {
        flex: 0 0 auto;
        width: 100%;
    }
}

// .card_nom_header {
//     background-color: var(--kt-content-bg-color) !important;
// }
.wrapper_clone {
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 20px;
    color: #181c3289;
}

.card_wrapper {
    width: 100%;
    max-width: 300px;
    height: 230px;
    border-radius: 15px;
    padding: 1.5rem;
    position: relative;
    display: flex;
    align-items: flex-end;
    transition: 0.4s ease-out;
    box-shadow: 0px 7px 10px rgba(black, 0.5);
    &:hover {
        transform: translateY(20px);
        &::before {
            opacity: 1;
        }
        .info {
            opacity: 1;
            transform: translateY(0px)
        }
    }
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 15px;
        background: rgba(black, 0.6);
        z-index: 2;
        transition: 0.5s;
        opacity: 0;
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: fill;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 15px;
    }
}

.info {
    position: relative;
    z-index: 3;
    color: white;
    opacity: 0;
    transform: translateY(30px);
    transition: 0.5s;
    height: 100%;
    width: 100%;
    h1 {
        margin: 0px
    }
    .info_content {
        height: 100%;
        overflow-y: scroll;
        a {
            text-decoration: none;
            font-weight: 600;
            color: var(--title-color);
            transition: all .3s;
            &:hover {
                background-color: var(--item-bg);
            }
            &:hover p {
                color: var(--p-color);
            }
        }
    }
    button {
        padding: 0.6rem;
        outline: none;
        border: none;
        border-radius: 3px;
        background-color: var(--kt-content-bg-color);
        color: black;
        font-weight: bold;
        cursor: pointer;
        transition: 0.4s ease;
        a {
            text-decoration: none;
            color: var(--title-head);
            &:hover p {
                color: var(--p-color);
                font-weight: 700;
            }
        }
    }
}

//responsive
.card_nom_header {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    .header_screen {
        background-color: #181c32a5;
        left: 0;
        z-index: 10;
    }
    .card-title,
    .card-toolbar {
        color: var(--title-color) !important;
        font-weight: 700 !important;
        z-index: 20;
    }
}

.card-toolbar {
    a {
        text-decoration: none;
        font-weight: 600;
        color: var(--title-color-responsive);
    }
}

.card_body_clone {
    height: 250px;
    overflow-y: scroll;
    a {
        text-decoration: none;
        font-weight: 600;
        color: var(--title-color-responsive);
        transition: all .4s;
        &:hover {
            background-color: var(--item-bg-responsive);
        }
        &:hover p {
            color: var(--p-color-responsive);
        }
    }
}

//TERCER CAD
// .container_img {
//     position: relative;
//     // width: 1100px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     flex-wrap: wrap;
//     padding: 30px;
//     height: 380px;
//     .card_img {
//         position: relative;
//         max-width: 300px;
//         height: 245px;
//         background: var(--kt-content-bg-color);
//         margin: 30px 10px;
//         padding: 20px 15px;
//         display: flex;
//         flex-direction: column;
//         box-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
//         transition: 0.3s ease-in-out;
//         border-radius: 15px;
//         &:hover {
//             height: 280px;
//             .content {
//                 margin-top: 50px;
//                 visibility: visible;
//                 opacity: 1;
//                 transition-delay: 0.2s;
//             }
//         }
//         .image {
//             position: relative;
//             width: 260px;
//             height: 260px;
//             top: -40%;
//             left: 0;
//             box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
//             z-index: 1;
//             border-radius: 15px;
//             img {
//                 max-width: 100%;
//                 border-radius: 15px;
//                 width: 100%;
//                 height: 150px;
//             }
//         }
//         .content {
//             position: relative;
//             top: -140px;
//             padding: 10px 15px;
//             color: #111;
//             height: 100%;
//             text-align: center;
//             visibility: hidden;
//             opacity: 0;
//             transition: 0.3s ease-in-out;
//             background-color: var(--kt-card-bg);
//             .info_content {
//                 height: 145px;
//                 overflow-y: scroll;
//                 a {
//                     text-decoration: none;
//                     color: var(--title-color);
//                     &:hover p {
//                         color: var(--p-color)
//                     }
//                 }
//             }
//             // overflow-y: scroll;
//         }
//     }
// }