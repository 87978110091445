.kr-smartform-modal-button {
  display: inline-block;
  background-color: #eee;
  padding: 5px 10px;
  border: 1px solid #ccc;
  font-size: 15px;
  cursor: pointer;
  width: 208px;
}

.kr-payment-button {
  background-color: #293c7a;
  color: #fff;
}
