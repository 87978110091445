// Card icons
.kr-card-icons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
  margin-right: 10px;

  @include column-gap(6px);

  cursor: pointer;

  span {
    height: 22px;
    width: 30px;
    cursor: pointer;

    &.kr-extra-brands {
      color: #777;
      font-family: Roboto, sans-serif;
      margin-left: 8px;
      font-size: 13px;
      max-width: 16px;

      @import "./card_tooltip";
    }

    &.kr-brand-icon {
      box-shadow: rgb(0, 0, 0, 7%) 0 6px 24px 0, rgb(0, 0, 0, 24%) 0 0 0 1px;
    }

    // Border
    svg {
      padding: 2px 4px;
      height: 18px;
      width: 22px;
      border-radius: 0;
      animation: icon-display 0.2s ease-in;
    }

    @keyframes icon-display {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }

    // Amex
    &.kr-amex svg {
      background-color: #006fce;
    }

    // Cabal
    &.kr-cabal,
    &.kr-cabal_debit {
      svg {
        padding: 0;
        width: 30px;
        height: 22px;
        background-color: #026095;
      }
    }

    // CB
    &.kr-cb {
      padding: 0;
      height: 22px;
      width: 34px;

      svg {
        padding: 0;
        height: 22px;
        width: initial;
      }
    }

    // Kadeos
    &.kr-kadeos_culture,
    &.kr-kadeos_culture svg,
    &.kr-kadeos_gift,
    &.kr-kadeos_gift svg {
      padding: 0;
      height: 22px;
      width: 35px;

      image {
        cursor: pointer;
      }
    }

    // Naranja
    &.kr-naranja svg {
      box-sizing: border-box;
      width: 30px;
      height: 22px;
      padding: 3px 4px;
    }

    // Visa
    &.kr-visa svg,
    &.kr-visa_electron svg,
    &.kr-visa_debit svg {
      padding: 0 2px;
      height: 22px;
      width: 26px;
    }

    // Assets loading
    &.kr-loading-assets {
      height: 20px;
      width: 30px;

      svg {
        padding: 0;
        height: 20px;
        width: 30px;
      }
    }
  }
}
