i.kr-loader {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

i.kr-loader::before,
i.kr-loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  width: 5px;
  height: 5px;
  background-color: $color-primary;
}

i.kr-loader::before {
  top: calc(50% - 5px);
  left: calc(50% - 5px);
  animation: loader-6 0.8s cubic-bezier(0, 0, 0.24, 1.21) infinite;
}

i.kr-loader::after {
  top: 50%;
  left: 50%;
  animation: loader-7 0.8s cubic-bezier(0, 0, 0.24, 1.21) infinite;
}

@keyframes loader-6 {
  0%,
  100% {
    transform: none;
  }

  25% {
    transform: translateX(100%);
  }

  50% {
    transform: translateX(100%) translateY(100%);
  }

  75% {
    transform: translateY(100%);
  }
}

@keyframes loader-7 {
  0%,
  100% {
    transform: none;
  }

  25% {
    transform: translateX(-100%);
  }

  50% {
    transform: translateX(-100%) translateY(-100%);
  }

  75% {
    transform: translateY(-100%);
  }
}
