[data-theme="light"] {
    --border-color-pay: #e1e3ea;
}

[data-theme="dark"] {
    --border-color-pay: #ffffff;
}

.btn_copy {
    position: relative;
}

.container-plan {
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
}

.btn_copy::after {
    content: "Copiado!";
    position: absolute;
    left: -80px;
    background-color: black;
    color: white;
    padding: .6em;
    border-radius: 5px;
    -webkit-transition: all 1.3s ease-in-out;
    transition: all 1.3s ease-in-out;
}

.btn_copy:hover:after {
    position: absolute;
    left: -90px;
}

.back_spinner {
    z-index: 999999;
    // pointer-events: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(255, 255, 255);
    opacity: .8;
    transition: all 0.3s ease;
}

.back_spinner_form {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    background-color: rgba(25, 25, 25, 0.644);
    align-items: center;
    opacity: .8;
    transition: all 0.3s ease;
}

.spinner {
    // position: absolute;
    // top: 50%;
    border: 6px solid rgba(0, 0, 0, 0.1);
    width: 66px;
    height: 66px;
    border-radius: 50%;
    border-left-color: #09f;
    animation: spin 1s ease infinite;
    // background-color: white;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.kr-embedded {
    .kr-field-element {
        border: 1px solid var(--border-color-pay) !important;
        box-shadow: none !important;
        // background-color: wheat !important;
        // border-color: var(--border-color-pay) !important;
        color: var(--kt-input-solid-color);
        border-radius: 0.625rem;
        transition: color 0.2s ease;
        padding: 6px !important;
        height: 100% !important;
    }
    .kr-payment-button {
        color: #fff !important;
        border-radius: 3px;
        background-color: #243F8E !important;
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
        &:hover {
            box-shadow: 0px 5px 18px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
        }
    }
    .kr-field-wrapper {
        // padding: 6px !important;
        height: 100% !important;
    }
}

//ANIMACION CHECK
$circle-length: 151px;
$check-length: 36px;
@keyframes scaleAnimation {
    0% {
        opacity: 0;
        transform: scale(1.5);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes drawCircle {
    0% {
        stroke-dashoffset: $circle-length;
    }
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes drawCheck {
    0% {
        stroke-dashoffset: $check-length;
    }
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

#successAnimationCircle {
    stroke-dasharray: $circle-length $circle-length;
    stroke: #53D635;
}

#successAnimationCheck {
    stroke-dasharray: $check-length $check-length;
    stroke: #53D635;
}

#successAnimation.animated {
    animation: 1s ease-out 0s 1 both scaleAnimation;
    #successAnimationCircle {
        animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCircle, 0.3s linear 0.9s 1 both;
    }
    #successAnimationCheck {
        animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCheck, 0.3s linear 0.9s 1 both;
    }
}

#replay {
    background: rgba(255, 255, 255, 0.2);
    border: 0;
    border-radius: 3px;
    bottom: 100px;
    color: #FFF;
    left: 50%;
    outline: 0;
    padding: 10px 30px;
    position: absolute;
    transform: translateX(-50%);
    &:active {
        background: rgba(255, 255, 255, 0.1);
    }
}