@use "package/classic/discount-panel";
@import "package/classic/variables";
@import "breakpoint_definition";

/* Font */
@import "https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap";

/* Mixins */
@import "mixins/breakpoint";
@import "mixins/flex";
@import "desktop/mixin/clearfix";
@import "desktop/mixin/aspect_ratio";
@import "desktop/mixin/vertical_align";
@import "desktop/mixin/words";
@import "desktop/mixin/form_fields";
@import "desktop/mixin/placeholder";

/* Vendors */
@import "animations/base";
@import "animations/flippers/flipInX";

/* Popin */
@import "package/classic-popin/index";

/* Discount Panel */
@include discount-panel.root;

/* Brand buttons */
@import "package/classic/brand-button";

/* Skeleton */
@import "package/classic/skeleton";

.kr-embedded,
.kr-smartform {
  @import "package/classic/custom_field";
  @import "package/classic/error";
  @import "package/classic/modal";
  @import "package/classic/button";
  @import "package/classic/help-button";
}

/* Smart Form */
.kr-smartform {
  z-index: 1;
  width: $smartform-width;

  &.kr-smartform--compact {
    width: $smartform-compact-width;
  }

  @import "package/classic-popin/smartform/index";
  @import "package/classic/smartform/index";
  @import "package/classic/smartform/smart_button";

  /**
   * See KJS-1882 | Necessary to increase selector weight in SmartForm case.
   *   Without it, all: initial rule in _initial.scss will revert width
   *   to .kr-smartform's ($smartform-width)
   */
  .kr-embedded,
  .kr-embedded .kr-payment-button,
  .kr-embedded .kr-form-error {
    width: 100%;
  }
}

@import "package/classic/layer";
@import "package/classic/smartform/smart_button";

/* Default (embedded) */
.kr-embedded {
  width: $inner-width;
  font-family: Roboto, sans-serif;

  &.kr-outside-smartform {
    width: $smartform-width;
  }

  // Flex direction
  @include flex-direction(row);

  /* Components */
  @import "package/classic/brand-selector";
  @import "package/classic/field";
  @import "package/classic/select";
  @import "package/classic/spinner";
  @import "package/classic/wrappers";
  @import "package/classic/icon";
  @import "package/classic/disabled";
  @import "package/classic/delete-button";
  @import "package/classic/text-field";
  @import "package/classic/wallet_tabs";
  @import "package/classic/wallet_card_list";
  @import "package/classic/visibility_button";
  @import "package/classic/layer";
  @import "package/classic/smartform/card_header";

  &.kr-internet-explorer {
    @import "package/classic/internet-explorer";
  }

  &.kr-ios,
  &.kr-macos {
    @import "package/classic/ios";
  }

  &.kr-ios-9 {
    @import "package/classic/old-browser";
  }

  @include discount-panel.embedded;
}

/* PATCH */
@import "package/classic/legacy";

/* ALIAS .kr-smart-form for .kr-smartform */
.kr-smart-form {
  /*  stylelint-disable-next-line scss/at-extend-no-missing-placeholder */
  @extend .kr-smartform;
}
