@use "sass:math";

.kr-popin-modal-header .kr-popin-modal-header-image {
  margin-left: math.div($form-width + $sides-margin, 2) - 62.5px;
  left: 0;
}

&[kr-popin][krvue].kr-embedded .kr-field.kr-text {
  margin: 12px 12px 12px 0;
}
