$smartbutton-height: 52px;
$button-width: 300px;

.kr-smart-button-wrapper {
  margin: 10px 0;

  @import "package/classic/error";

  .kr-form-error {
    width: 300px;
    margin: 5px 0 0;

    span {
      font-size: 13px;
    }
  }
}

/* KJS-2563: Must be outside to allow the styling with just .kr-smart-button selector */
.kr-smart-button {
  display: flex;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  padding: 16px 20px 16px 12px;
  width: $button-width;
  border: 1px solid #b7b7b7;
  box-shadow: rgb(149, 157, 165, 15%) 0 0 24px;
  background-color: #fff;

  @import "./apple_pay";

  &.kr-big-label {
    padding: 10px 20px 10px 12px;
  }

  &:hover:not(.kr-loading, .kr-disabled) {
    border: 1px solid $color-primary;
    background-color: #fff;
  }

  &:focus:not(.kr-loading, .kr-disabled) {
    background-color: #fff;
  }

  .kr-method-info {
    margin: 1px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    @include column-gap(15px);

    height: 24px;
    cursor: pointer;

    // Icon
    .kr-method-icon {
      display: flex;
      cursor: pointer;

      svg {
        fill: $color-primary;
        width: 24px;
        height: 24px;
      }
    }

    label.kr-amount-label {
      font-family: Roboto, sans-serif;
      color: #555;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    // Label
    .kr-label {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 82%;

      &.kr-big-label {
        flex-direction: column;
      }

      label.kr-method-label {
        font-family: Roboto, sans-serif;
        text-transform: none;
        color: #888;
        line-height: 20px;
        vertical-align: middle;
        font-size: 13px;
      }
    }
  }

  // Card icons
  &.kr-cards {
    @import "./../smartform/card_icons";

    .kr-method-info {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      @include column-gap(0);

      &.kr-big-label {
        flex-direction: column;
      }
    }
  }

  // Loading animation
  .kr-loading-animation {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    @import "./../../no-theme/smartform/loading_animation";
  }
}
