@import "../../../mixins/smartform/compact";

.kr-smartform-methods {
  position: relative;
  width: 100%;
  z-index: 0;

  .kr-methods {
    z-index: 1;
    box-sizing: border-box;
  }

  .kr-methods-list .kr-methods-list-options {
    &.kr-methods-list-options--compact {
      @include compact-smartform;
    }

    .kr-method-label {
      @import "./card_icons";
    }

    .kr-methods-list-options-item {
      // tooltip z-index issue on firefox
      position: relative;
      display: block;

      &.kr-cards {
        z-index: 2;
      }

      &:not(.kr-cards) {
        z-index: 1;
      }
    }
  }

  // hover background
  .kr-methods-list .kr-method-label,
  .kr-methods-list .kr-methods-group-activator,
  .kr-methods .kr-method-content .kr-method-label {
    @import "./apple_pay";

    border: none;
    outline: none;
    background-color: #fff;

    &:hover:not(.kr-loading, .kr-locked),
    &:focus:not(.kr-loading, .kr-locked) {
      background-color: #eee;
    }
  }

  // No header on embedded mode
  .kr-methods-list .kr-methods-list-header {
    display: block;
    padding-top: 16px;
  }

  .kr-methods-list .kr-methods-group-activator,
  .kr-methods-list .kr-method-label,
  .kr-methods .kr-method-content .kr-method-label {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    padding: 0;

    .kr-method-label-wrapper {
      position: static;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    &.kr-locked .kr-method-label-wrapper {
      cursor: not-allowed;
    }

    // Icon
    .kr-method-icon {
      display: none;
      width: 20px;
      height: 20px;
      position: absolute;
      left: 20px;
      cursor: pointer;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    // Label
    label {
      cursor: pointer;
    }

    .kr-methods-group-label {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    // Arrows
    .kr-arrow-left,
    .kr-arrow-right {
      position: absolute;
      height: 100%;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      svg {
        fill: #444;
        width: 20px;
        height: 20px;
      }
    }

    .kr-arrow-right {
      right: 10px;
    }

    .kr-arrow-left {
      left: 10px;
    }

    // Loading
    &.kr-loading {
      cursor: wait;

      label {
        cursor: wait;
      }
    }

    // Locked
    &.kr-locked {
      cursor: default;
    }
  }

  .kr-methods-list-options {
    .kr-methods-group {
      .kr-methods-group-activator {
        .kr-method-tooltip {
          position: relative;
        }

        @import "./card_tooltip";
      }
    }
  }

  .kr-methods .kr-method-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    transition-delay: 0s, 0s;
    transition-property: opacity, max-height;
    opacity: 1;

    &--invisible {
      transition-delay: 0s, 0.5s;
      transition-duration: 0.5s, 0s;
      transition-property: opacity, max-height;
      opacity: 0;
      max-height: 0;
    }

    .kr-back-button {
      transition: all 0.3s;
    }

    &.kr-disabled-back-button .kr-back-button,
    &.kr-hidden-back-button .kr-back-button {
      display: none;
    }
  }

  // Cards with no back button - extra top padding
  .kr-methods.kr-method-selected.kr-method-cards > .kr-disabled-back-button {
    &:not(.kr-method-content--invisible, .kr-secure-iframe-visible) {
      padding-top: 15px;
    }
  }
}
