$bg-color: #ededed;

.kr-skeleton-wrapper {
  // Header
  .kr-skeleton-header {
    display: flex;
    width: $smartform-width;
    height: $smartform-header-height;
  }

  // Labels
  .kr-skeleton-method-label {
    display: flex;
    width: $smartform-width;
    height: $smartform-option-height;
    margin: 10px 0;
  }
}
