// Positions
.kr-delete-button-wrapper {
  cursor: pointer;
  display: block;
  position: relative;
  height: 100%;
  padding: 0 13px 0 7px;

  &:not(.kr-visible) {
    display: none;
  }

  .kr-delete-button {
    display: block;
    height: 100%;

    @include flexbox;
    @include justify-content(center);
    @include align-items(center);
    @include flex-direction(row);

    .kr-delete-icon {
      @include flexbox;
      @include flex-direction(column);
      @include align-items(center);

      cursor: pointer;

      svg {
        width: 16px;
        height: 18px;

        path {
          &.icon {
            fill: $color-grey-font;
          }

          &.background {
            fill: $color-light;
          }
        }
      }

      &.kr-error svg path {
        &.icon {
          fill: $color-icon-error;
        }

        &.background {
          fill: $color-light;
        }
      }
    }
  }

  &.kr-card-list {
    height: 18px;
    padding: 0 14px 0 0;

    .kr-delete-button .kr-delete-icon svg {
      height: 16px;
    }
  }

  // Icon
  &.kr-visible + .kr-icon-wrapper-root {
    right: -5px;
  }
}
