.kr-layer {
  z-index: 2;

  &.kr-form-layer {
    position: fixed;
    z-index: 11000;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgb(30, 30, 30, 80%);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .kr-layer-background {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #232323;
    opacity: 0.7;
    z-index: 10;
  }

  .kr-layer-message {
    color: #fff;
    z-index: 12;
    cursor: pointer;

    &.kr-centered {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
