.kr-smartform-methods {
  min-height: 354px;
  overflow: visible;
  background-color: #fff;
  display: block;

  .kr-methods-list .kr-methods-list-header,
  .kr-methods-list .kr-methods-group-activator,
  .kr-methods-list .kr-method-label,
  .kr-methods .kr-method-content .kr-method-label {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    color: #444;

    label {
      font-family: Roboto, sans-serif;
      font-size: 14px;
    }
  }

  // Header info
  .kr-methods-list .kr-methods-list-header {
    padding: 20px 15px 5px 17px;
    color: $color-primary;
    cursor: default;
  }

  .kr-methods-list.kr-group-selected {
    .kr-methods-list-header {
      padding-top: 0;
      padding-bottom: 15px;
    }
  }

  .kr-methods-list {
    height: 358px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    &.kr-error:not(.kr-method-selected) {
      position: relative;
    }

    .kr-methods-list-options {
      display: block;
      height: calc(100% - 46px);
      overflow: auto;

      &::-webkit-scrollbar {
        width: 6px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #d1d7e3;
        border-radius: 5px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #7e8dae;
        border-radius: 5px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: $color-primary;
      }
    }
  }

  // Menu elements
  .kr-methods-list .kr-methods-list-options .kr-methods-group-activator,
  .kr-methods-list .kr-methods-list-options .kr-method-label {
    margin: 12px 15px 10px;
    padding-left: 47px;
    height: 48px;
    width: calc(100% - 30px);
    border: 1px solid #b7b7b7;
    box-shadow: rgb(149, 157, 165, 15%) 0 0 24px;
    transition: 0.3s background-color;

    @import "./../../classic/smartform/card_icons";
    @import "../../classic/smartform/apple_pay";

    &:hover:not(.kr-loading, .kr-locked) {
      border: 1px solid $color-primary;
      background-color: #fff;
    }

    &:focus:not(.kr-loading, .kr-locked) {
      background-color: #fff;
    }

    &.kr-no-icons {
      padding: 12px 20px 12px 12px;
    }

    // Icon
    .kr-method-icon {
      display: flex;
      top: 0;
      left: 15px;
      height: 100%;
      flex-direction: column;
      justify-content: center;

      svg {
        fill: $color-primary;
      }
    }

    // Label
    label {
      font-family: Roboto, sans-serif;
      font-size: 14px;
      color: #444;
      transition: 0.3s color;
    }

    // Arrow
    .kr-arrow-right svg {
      fill: $color-primary;
      display: none;
    }

    // Loading animation
    .kr-loading-animation {
      display: block;
      position: absolute;
      right: 10px;
      width: 20px;
      height: 20px;

      @import "../../no-theme/smartform/loading_animation";
    }

    &.kr-loading {
      border: 1px solid $color-primary;
      background-color: #f4f4f4;
    }

    &.kr-locked {
      background-color: #eee;
      border: 1px solid #ddd;
      cursor: not-allowed;

      label {
        color: #999;
        cursor: not-allowed;
      }
    }
  }

  .kr-methods-list-options {
    .kr-methods-group {
      .kr-methods-group-activator {
        padding: 0 20px;
        display: flex;
        justify-content: flex-start;

        @include column-gap(5px);
        @import "./../../classic/smartform/card_tooltip";

        .kr-method-icon {
          position: relative;
          display: flex;
          left: 0;
        }

        .kr-extra-brands {
          color: #777;
          font-family: Roboto, sans-serif;
          margin-left: 5px;
          font-size: 13px;
        }

        label {
          flex-grow: 1;
          text-align: end;
        }
      }
    }
  }
}

// Card Icons
$button-width: 270px;

.kr-methods-list .kr-methods-list-options .kr-method-label.kr-cards {
  padding: 12px 20px 12px 12px;
}

.kr-methods-list .kr-methods-list-options .kr-method-label {
  @import "./../../classic/smartform/card_icons";
}
