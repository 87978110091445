@use "sass:list";

@mixin initial($exclude: ()) {
  $base-exclude: (svg, g, path, circle, rect, ellipse, text, lineargradient, stop, i);
  $selector: list.join($base-exclude, $exclude, $separator: comma);

  *:not(#{$selector}) {
    all: initial;
  }
}
