[data-theme="light"] {
    --bg-cca: #181c32;
    --bg-score: #656565;
    --bg-row_second_scan: #F2F2F2;
    --bg-row_three_scan: #f9f9f9;
    --bg-row_four_scan: #F2F2F2;
    --bg-row_current_event: #F2F2F2;
}

[data-theme="dark"] {
    --bg-cca: #000000;
    --bg-row_second_scan: #1B1D36;
    --bg-row_three_scan: #272940;
    --bg-row_four_scan: #32344A;
    --bg-row_current_event: #3D3F54;
    --bg-score: #484848;
}

.bg_second_row {
    background-color: var(--bg-row_second_scan);
}

.bg_three_row {
    background-color: var(--bg-row_three_scan);
}

.bg_four_row {
    background-color: var(--bg-row_four_scan);
}

.bg_score_event {
    background-color: var(--bg-row_current_event);
}


/* .bg_surebet-green {
    background-color: #3CD630;
}
.bg_surebet-green_light {
    background-color: #C0F2BC;
} */

.bg_surebet-pink {
    background-color: #C843D6;
}

.bg_surebet-pink_light {
    background-color: #F6DFF8;
}

.bg_surebet-blue {
    background-color: #4CC1ED;
}

.bg_surebet-blue_light {
    background-color: #E1F5FC;
}

.bg_surebet-gray {
    background-color: #5b6978;
}

.bg_surebet-yellow {
    background-color: #FFD113;
}

.bg_surebet-yellow_light {
    background-color: #FFF7D7;
}

.bg_surebet-red {
    background-color: #ff2d55;
}

.bg_surebet-middle {
    background-color: #3CD630;
}

.bg_surebet_gray {
    background-color: var(--bg-cca);
}

.bg_surebet_score {
    background-color: var(--bg-score);
}

.bg_surebet_gray_filter {
    background-color: #696464;
}

.bg_surebet_graylight {
    background-color: #f1f1f1;
}

.bg_surebet_primary {
    background-color: #1a252f;
}

.bg-gray_light {
    background-color: #C2C2C2;
}


/* .info_percent {
    height: 20px;
} */

.box_total {
    height: 45px;
}

input:focus {
    outline: 1px solid rgba(200, 197, 197, 0.879);
}

.inputCuota {
    border: 1px solid rgba(0, 0, 0, 0.18);
    text-align: center;
    width: 50px;
    height: 26px;
    border-radius: 9px;
}

.inputCuotaTot {
    width: 150px !important;
}

.custom-radio-checkbox {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.custom-radio-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}


/* Create a custom checkbox */

.checkmark {
    position: absolute;
    top: 3px;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border-radius: 5px;
    border: 1px solid rgba(107, 105, 105, 0.602);
}


/* On mouse-over, add a grey background color */

.custom-radio-checkbox:hover input~.checkmark {
    background-color: #ccc;
}


/* When the checkbox is checked, add a blue background */

.custom-radio-checkbox input:checked~.checkmark {
    background-color: #262829;
    border: 1px solid #252628be;
}


/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}


/* Show the checkmark when checked */

.custom-radio-checkbox input:checked~.checkmark:after {
    display: block;
}


/* Style the checkmark/indicator */

.custom-radio-checkbox .checkmark:after {
    left: 6px;
    top: 3px;
    width: 6px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.button_toggle {
    transition: 0.3s all ease-in;
    border-radius: 70px;
}

.button_toggle button {
    bottom: 3px;
    width: 15px;
    height: 15px;
    border: none;
    border-radius: 50%;
    background-color: #fff;
    outline: none;
    transition: 0.22s all ease-in;
}

.text_league {
    font-size: 10px;
}

.text_event {
    font-weight: 600;
}

.font-bold {
    font-weight: 800;
}

.font-semibold {
    font-weight: 600;
}


/* BLUE */

.text-blue-cca {
    color: #4CC1ED;
}

.surebet_time-blue {
    background-color: #4CC1ED;
}

.surebet_percent-blue::before {
    content: "";
    border-bottom: 14px solid #4CC1ED;
    border-left: 15px solid transparent;
    border-right: 0px solid #4CC1ED;
    right: 0;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 0px;
    height: 0px;
}

.surebet_time-blue::after {
    content: "";
    border-bottom: 15px solid transparent;
    border-left: 15px solid #E1F5FC;
    border-top: 0 solid transparent;
    left: 0px;
    position: absolute;
    top: 0;
    right: 0;
    width: 0px;
    height: 0px;
}


/* YELLOW */

.text-yellow-cca {
    color: #FFD113;
}

.surebet_time-yellow {
    background-color: #FFD113;
}

.surebet_percent-yellow::before {
    content: "";
    border-bottom: 14px solid #FFD113;
    border-left: 15px solid transparent;
    border-right: 0px solid #FFD113;
    right: 0;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 0px;
    height: 0px;
}

.surebet_time-yellow::after {
    content: "";
    border-bottom: 15px solid transparent;
    border-left: 15px solid #FFF7D7;
    border-top: 0 solid transparent;
    left: 0px;
    position: absolute;
    top: 0;
    right: 0;
    width: 0px;
    height: 0px;
}


/* GREEN */


/* .text-green-cca{
    color: #3CD630;
}
.surebet_time-green{
    background-color: #3CD630;
}
.surebet_percent-green::before{
    content: "";
    border-bottom: 11px solid #3CD630;
    border-left: 15px solid transparent;
    border-right: 0px solid #3CD630;
    right: 0;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 0px;
    height: 0px;
}
.surebet_time-green::after{
    content: "";
    border-bottom: 10px solid transparent;
    border-left: 15px solid white;
    border-top: 0 solid transparent;
    left: 0px;
    position: absolute;
    top: 0;
    right: 0;
    width: 0px;
    height: 0px;
} */


/*PINK*/

.surebet_time-pink {
    background-color: #C843D6;
}

.surebet_percent-pink::before {
    content: "";
    border-bottom: 14px solid #C843D6;
    border-left: 15px solid transparent;
    border-right: 0px solid #C843D6;
    right: 0;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 0px;
    height: 0px;
}

.surebet_time-pink::after {
    content: "";
    border-bottom: 15px solid transparent;
    border-left: 15px solid #F6DFF8;
    border-top: 0 solid transparent;
    left: 0px;
    position: absolute;
    top: 0;
    right: 0;
    width: 0px;
    height: 0px;
}

@media screen and (max-width: 993px) {
    .surebet_percent-blue::before {
        border: 0;
    }
    .surebet_time-blue::after {
        border: 0;
    }
    .surebet_percent-green::before {
        border: 0;
    }
    .surebet_time-green::after {
        border: 0;
    }
    .surebet_percent-yellow::before {
        border: 0;
    }
    .surebet_time-yellow::after {
        border: 0;
    }
    .surebet_percent-pink::before {
        border: 0;
    }
    .surebet_time-pink::after {
        border: 0;
    }
}