/* .card_avatar:hover {
    box-shadow: 0px 0px 15px 5px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
} */

[data-theme="light"] {
    --opacity-bg: 0.9;
    --bg-icon: "#ffffff";
    --bg-button: '#004451';
    --bg-card-img: #95959534;
    --color-shadow: 0 0 0;
    --opacity-avatar: 0.3;
    /* --border-select:  */
}

[data-theme="dark"] {
    --opacity-bg: 0.4;
    --bg-icon: "#1b1b29";
    --bg-button: '#0B7488';
    --bg-card-img: #E7E7E7;
    --color-shadow: 255 255 255;
    --opacity-avatar: 0.5
}

.card_avatar img {
    border: 5px solid var(--bg-card-img);
}

/* .active img {
    border: 5px solid #00a3ff !important;
} */

.card_avatar img:hover {
    /* transition: .1s all ease-in-out; */
    box-shadow: 0px 0.2rem 1rem 0.2rem rgb(var(--color-shadow) / var(--opacity-avatar)), 0 4px 6px -4px rgb(var(--color-shadow) / var(--opacity-avatar));
}

.modal_content_body {
    background-color: var(--kt-content-bg-color);
}

.btn_delete {
    position: absolute;
    left: 100%;
    top: 100%;
}