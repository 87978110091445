// Hidden (out of view)
.kr-embedded,
kr-smartform,
kr-smart-form {
  &.kr-out-of-view-form,
  &.kr-out-of-view-form[krvue],
  &.kr-out-of-view-form[krvue][kr-popin] {
    position: fixed;
    top: -4000px;
    left: -4000px;
    right: -4000px;
    bottom: -4000px;
  }
}

.kr-embedded {
  display: block;

  &.kr-embedded-flex-display {
    display: flex;
    flex-wrap: wrap;

    &.kr-embedded-flex-direction-column {
      flex-direction: column;
    }

    &.kr-embedded-flex-direction-row {
      flex-flow: row wrap;
    }
  }

  .kr-pan,
  .kr-expiry,
  .kr-security-code,
  .kr-installment-number,
  .kr-first-installment-delay,
  .kr-identity-document-type,
  .kr-identity-document-number,
  .kr-card-holder-name,
  .kr-card-holder-mail,
  .kr-do-register,
  div[id^="kr-field-app-"] {
    position: relative;
    order: 0;
  }

  /**
   * Keep regular fields undisplayed until they have class "kr-field-element".
   * That is until component are mounted and their v-show condition takes over
   * the display condition.
   *
   * @since KJS-2419
   */
  .kr-installment-number,
  .kr-first-installment-delay,
  .kr-identity-document-type,
  .kr-identity-document-number,
  .kr-card-holder-name,
  .kr-card-holder-mail,
  .kr-do-register {
    &:not(.kr-field-element) {
      display: none;
    }
  }

  div[id^="kr-field-app-"] {
    width: 100%;
  }

  .kr-form-error,
  .kr-payment-button,
  .kr-discount-panel--container,
  .kr-discount-panel-wrapper {
    order: 30;
  }
}

// Rows
.kr-embedded[krvue] {
  .kr-expiry.kr-size-half,
  .kr-security-code.kr-size-half {
    width: calc(50% - 5px);
  }
}
