// Menu element
&.kr-apple_pay:not(.kr-disabled) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #000;
  padding: 0;
  transition: none;
  height: 50px;

  &:hover:not(.kr-loading, .kr-locked),
  &:focus:not(.kr-loading, .kr-locked),
  &.kr-hover:not(.kr-loading, .kr-locked),
  &.kr-loading,
  &.kr-locked {
    background-color: #000;
  }

  .kr-apple-pay,
  .kr-apple-pay apple-pay-button {
    pointer-events: none;
  }
}

apple-pay-button {
  --apple-pay-button-width: 120px;
  --apple-pay-button-height: 30px;
  --apple-pay-button-border-radius: 4px;
  --apple-pay-button-padding: 1px;
  --apple-pay-button-box-sizing: border-box;
}
