@keyframes kr-bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
}

@keyframes kr-bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
}

.kr-spinner {
  box-sizing: border-box;
  display: none;
  margin: 0 auto;
  text-align: center;
  padding-top: 1px;
  height: 22px;
  width: 70px;
}

.kr-spinner > div {
  width: 18px;
  height: 18px;
  background-color: #cfcfcf;
  border-radius: 100%;
  display: inline-block;
  animation: kr-bouncedelay 1.4s infinite ease-in-out both;
}

.kr-spinner .kr-bounce1 {
  animation-delay: -0.32s;
}

.kr-spinner .kr-bounce2 {
  animation-delay: -0.16s;
}
