.kr-payment-button {
  visibility: hidden;
}

.kr-embedded .kr-payment-button {
  visibility: visible;
}

.kr-smartform-wrapper.kr-type-embedded {
  @import "./card_header";
  @import "./methods_list";
  @import "../../classic-popin/smartform/header";
  @import "./errors";

  width: 100%;
}

@import "./skeleton";
@import "./header";
