@mixin modal-container($width) {
  .kr-smartform-modal-views-container {
    display: flex;
    width: $width;
    overflow: hidden;
    position: relative;
    background-color: #fff;
    transition: width 0.5s;
    z-index: 1;

    // For the select fields (must be displayed outside the container)
    &.kr-smartform-modal-view-cards:not(.kr-secure-iframe-visible) {
      overflow: visible;
    }

    .kr-smartform-modal-view {
      display: flex;
      width: 100%;
      transform-origin: center center;
      transition: transform 0.5s, opacity 0.5s;
      transform: translateX(0);
      position: relative;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      flex-shrink: 0;
      background-color: #fff;
      opacity: 1;

      .kr-smartform-methods,
      .kr-smartform-methods .kr-method-label {
        transition: opacity 0.3s;
      }

      @for $level from 1 through 3 {
        &--level-#{$level} {
          transform: translateX(-100% * $level);
        }
      }

      // &:not(&--active) // not working properly on android 4
      &:not(.kr-smartform-modal-view--active) {
        opacity: 0;

        .kr-smartform-methods,
        .kr-smartform-methods .kr-method-label {
          opacity: 0;
        }
      }

      &:not(&--active, &--popin) {
        height: 0;
      }
    }
  }
}
