[data-theme="light"] {
    --head-color: #181c32;
}

[data-theme="dark"] {
    --head-color: #5e6278;
}

.card_acordion span,
.toast_text {
    font-size: 16px !important;
}

.accordion-header {
    border-radius: 0.8rem !important;
}

.column_head {
    background-color: var(--kt-content-bg-color);
    color: var(--head-color);
}

textarea {
    resize: none;
}