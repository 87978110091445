.kr-popin-modal-footer {
  border: 5px solid #fff;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  width: calc(100% - 10px);
  height: 60px;

  .kr-whitelabel-logo img.kr-logo-payzen {
    margin-right: 12px;
    height: 30px;
  }
}
