.kr-delete-button-wrapper {
  cursor: pointer;

  &:not(.kr-visible) {
    display: none;
  }

  .kr-delete-button {
    height: 100%;
    display: flexbox;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .kr-delete-icon {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;

      svg {
        fill: #000;
        width: 16px;
        height: 18px;
      }

      &.kr-error svg path {
        &.icon {
          fill: red;
        }
      }
    }
  }

  &.kr-card-list {
    .kr-delete-button .kr-delete-icon svg {
      height: 16px;
    }
  }
}
