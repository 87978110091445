@import "initial";

.kr-embedded,
.kr-checkout,
.kr-smart-button,
.kr-discount-panel {
  @include initial;
  @import "normalize";
}

/**
 * Selector must exclude .kr-smart-button and its subtree to avoid initial rule
 * to override smart-button styles.
 * Computed rules (.kr-smartform *:not(...)) will have a higher weight
 * than .kr-smart-button alone.
 */
.kr-smartform {
  @include initial;
}

@import "package.no-theme";
@import "package.classic";
