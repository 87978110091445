$method-item-height: 40px;

.kr-smartform-methods {
  min-height: 350px;
  position: relative;
  width: 100%;
  overflow: visible;

  .kr-methods-list {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
  }

  .kr-methods {
    z-index: 1;
    box-sizing: border-box;
    display: block;
    transition: all 0.3s;
    transition-delay: 0.2s;
  }

  .kr-methods-list .kr-methods-group-activator,
  .kr-methods-list .kr-method-label,
  .kr-methods .kr-method-content .kr-method-label {
    cursor: pointer;

    &:hover:not(.kr-loading, .kr-locked) {
      background-color: #eee;
    }

    label {
      cursor: pointer;
    }
  }

  .kr-methods-list .kr-methods-list-header,
  .kr-methods-list .kr-methods-group-activator,
  .kr-methods-list .kr-method-label,
  .kr-methods .kr-method-content .kr-method-label {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    .kr-method-icon {
      display: none;
    }

    .kr-arrow-left,
    .kr-arrow-right {
      position: absolute;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;

      svg {
        fill: #444;
        width: 20px;
        height: 20px;
      }
    }

    .kr-arrow-right {
      right: 0;
    }

    .kr-arrow-left {
      left: 10px;
    }
  }

  .kr-methods-list .kr-methods-group-activator,
  .kr-methods-list .kr-method-label {
    transition: color 0.1s;

    // Loading animation
    .kr-loading-animation {
      display: none;
    }

    // Loading
    &.kr-loading {
      border-top: 1px solid #808080;
      border-bottom: 1px solid #808080;
      background-color: #eee;
      cursor: wait;

      label {
        cursor: wait;
      }
    }

    // Locked
    &.kr-locked {
      color: #ddd;
      cursor: default;
    }
  }
}
