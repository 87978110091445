@use "sass:math";

@mixin compact-smartform($gap: 10px) {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: $gap;
  margin: 10px 0;

  .kr-methods-list-options-item {
    width: 100%;

    &:last-of-type:nth-of-type(odd) {
      grid-column: 1 / span 2;
    }

    .kr-method-label,
    .kr-methods-group-activator {
      margin: 0;

      label {
        white-space: break-spaces;
      }
    }
  }

  @content;
}
