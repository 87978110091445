.kr-popin-modal-footer {
  background-color: #fff;

  @include flexbox;
  @include justify-content(flex-end);
  @include align-items(center);

  height: $footer-height;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 0;

  img {
    margin-right: 30px;
    height: 40px;
  }

  img.kr-logo-payzen {
    height: 40px;
  }

  img.kr-logo-lyra {
    height: 40px;
  }

  img.kr-logo-sogecom {
    height: 20px;
  }

  img.kr-logo-cdn {
    height: 30px;
  }
}
