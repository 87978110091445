@use "./variables" as *;

@mixin -default-panel {
  .kr-discount-panel.kr-discount-panel--default {
    .kr-discount-panel-content {
      overflow: hidden;
      max-height: 0;
      display: none;

      &--visible {
        display: flex;
        max-height: 100%;
      }
    }
  }
}

@mixin -base-inner-panel {
  .kr-discount-panel-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .kr-discount-panel-subtotal,
  .kr-discount-panel-discount {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .kr-discount-panel-discount {
    &--negative .kr-discount-panel-discount-value {
      color: $color-primary;
      font-weight: 700;
    }

    &--positive .kr-discount-panel-discount-value {
      color: $color-error;
      font-weight: 700;
    }

    .kr-discount-panel-discount-updating {
      font-weight: 500;
      animation: updating 1s infinite;
    }
  }
}

@keyframes updating {
  0% {
    color: #666;
  }

  50% {
    color: #999;
  }

  100% {
    color: #666;
  }
}

@mixin embedded {
  .kr-discount-panel--container {
    width: 100%;
    display: flex;
  }

  .kr-discount-panel-wrapper {
    width: 100%;

    @include -default-panel;
    @include -base-inner-panel;
  }
}

@mixin root {
  @at-root .kr-discount-panel {
    @include -base-inner-panel;
  }
}
