.kr-card-header {
  box-sizing: border-box;
  position: relative;
  background-color: #f4f4f4;
  padding: 20px 20px 10px;
  display: flex;
  margin-bottom: 10px;
  z-index: 2;
  width: 100%;

  span.kr-card-header-label {
    font-size: 12px;
    color: #999;
    user-select: none;
    cursor: default;
  }

  @import "./card_icons";
}
