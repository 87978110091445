:root {
    --bs-accordion-btn-icon-width: 1.15rem;
    --bs-accordion-btn-icon-transform: rotate(-180deg);
    --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
}

[data-theme="light"] {
    --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23181C32'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    --bg-tabs-arbitraje: #00b74f;
    --bg-tab-active-arbitraje: #1C8E55;
    --shadow-filters: 0px 8px 25px rgb(124 125 157 / 25%);
    --bg-tabs-header: #37495a;
}

[data-theme="dark"] {
    --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    --bg-tabs-arbitraje: #1B1D36;
    --shadow-filters: 0px 8px 25px rgba(0, 0, 0, 0.5);
    --bg-tab-active-arbitraje: #151521;
    --bg-tabs-header: #1a252f;
}

.box_options .option {
    background: #fff;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 0 10px;
    border-radius: 5px;
    cursor: pointer;
    padding: 5px 15px;
    border: 1px solid lightgrey;
    transition: all 0.3s ease;
    font-size: 5px;
}

.box_options .option .dot {
    height: 18px;
    width: 18px;
    background: #d9d9d9;
    border-radius: 50%;
    position: relative;
}

.box_options .option .dot::before {
    position: absolute;
    content: "";
    top: 3px;
    left: 3px;
    width: 12px;
    height: 12px;
    background: #0069d9;
    border-radius: 50%;
    opacity: 0;
    transform: scale(1.5);
    transition: all 0.3s ease;
}

.box_options input[type="radio"] {
    display: none;
}

.bg_surebet_live {
    background-color: #dfe3e7;
}


/* table {
    border-collapse: collapse;
    border-radius: 0 0 1em;
    overflow: hidden;
} */

#option-1:checked:checked~.option-1,
#option-2:checked:checked~.option-2 {
    border-color: #0069d9;
    background: #0069d9;
}

#option-1:checked:checked~.option-1 .dot,
#option-2:checked:checked~.option-2 .dot {
    background: #fff;
}

#option-1:checked:checked~.option-1 .dot::before,
#option-2:checked:checked~.option-2 .dot::before {
    opacity: 1;
    transform: scale(1);
}

.box_options .option span {
    font-size: 14px;
    color: #808080;
    margin-left: 4px;
}

#option-1:checked:checked~.option-1 span,
#option-2:checked:checked~.option-2 span {
    color: #fff;
}


/* .content_calcu{
    top: 140px;
} */

.header_content {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    display: flex;
    justify-content: end;
    /* padding-left: 22rem; */
    /* padding-right: 10rem; */
    background-color: var(--kt-card-bg);
    z-index: 99;
    /* padding-bottom: 1rem; */
}

#kt_content {
    margin-top: 55px;
}

#content_logo .aside-logo .logo-default,
#content_logo .aside-logo .logo-minimize {
    border: none !important;
}

.logo_nav .aside-logo .logo-default {
    display: none;
}

.logo_nav .aside-logo .logo-minimize {
    display: block;
}

.logo_nav.content_logo .aside-logo .logo-default {
    display: block;
}

.logo_nav.content_logo .aside-logo .logo-minimize {
    display: none;
}

.calculadora_content {
    position: fixed !important;
    height: auto;
    right: 5rem;
    width: 34vw;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 800px;
    padding-bottom: 8rem;
    transition: width 0.1s ease-in-out;
}

/* @media screen and (min-height: 1000px) {
    .calculadora_content {
        height: 94vh !important;
    }
} */

@media screen and (min-width: 1320px) and (max-width: 1599px) {
    .calculadora_content {
        width: 36vw;
    }
}

@media screen and (min-width: 2400px) {
    .calculadora_content {
        width: 42vw;
    }
}

@media screen and (min-width: 2000px) and (max-width: 2399px) {
    .calculadora_content {
        width: 40vw;
    }
}

@media screen and (min-width: 1600px) and (max-width: 1999px) {
    .calculadora_content {
        width: 38vw;
    }
}

@media screen and (max-width: 1420px) {
    .calculadora_content {
        position: sticky !important;
        top: 71px;
        width: 100%;
        height: 94vh !important;
        padding-bottom: 0px;
    }
}

@media screen and (min-width: 1420px) and (max-width: 1999px) {
    [data-kt-aside-minimize="on"] .calculadora_content {
        position: fixed !important;
        right: 1rem;
        overflow-y: scroll;
        overflow-x: hidden;
        height: 94vh !important;
        width: 47.5vw !important;
        padding-bottom: 8rem;
    }
}

@media screen and (min-width: 2000px) and (max-width: 2399px) {
    [data-kt-aside-minimize="on"] .calculadora_content {
        position: fixed !important;
        right: 1rem;
        overflow-y: scroll;
        overflow-x: hidden;
        height: 800px;
        width: 48vw !important;
        padding-bottom: 8rem;
    }
}

@media screen and (min-width: 2400px) {
    [data-kt-aside-minimize="on"] .calculadora_content {
        position: fixed !important;
        right: 1rem;
        overflow-y: scroll;
        overflow-x: hidden;
        height: 800px;
        width: 48vw !important;
        padding-bottom: 8rem;
    }
}


/* .card_titulo {
    display: flex !important;
} */


/* .card_contenido {
    height: 0px;
    transition: height 0.35s ease-in-out;
}

.card_contenido.showa {
    height: 350px;
} */


/* @media screen and (max-width: 1600px) {
    .calcu_header h6,
    .calcu_header label{
        font-size: 10px;
    }  
} */

.card_titulo::before {
    flex-shrink: 0;
    width: var(--bs-accordion-btn-icon-width);
    height: var(--bs-accordion-btn-icon-width);
    content: "";
    margin-right: 1rem;
    background-image: var(--bs-accordion-btn-icon);
    background-repeat: no-repeat;
    background-size: var(--bs-accordion-btn-icon-width);
    transition: var(--bs-accordion-btn-icon-transition);
}

.card_titulo:not(.colapse)::before {
    background-image: var(--bs-accordion-btn-icon);
    transform: var(--bs-accordion-btn-icon-transform);
}


/* tabs */

.tabs {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.5);
}

.tab {
    width: 100%;
    color: white;
    overflow: hidden;
}

.tab-label {
    display: flex;
    justify-content: space-between;
    padding: .5em 1em;
    background: #2c3e50;
    font-weight: bold;
    cursor: pointer;
    align-items: center;
    /* Icon */
}

.tab-label:hover {
    background: #1a252f;
}

.tab-label::after {
    content: "\276F";
    transform: rotate(90deg);
    width: 1em;
    height: 1em;
    text-align: center;
    transition: all 0.35s;
}

.tab-content_cal {
    max-height: 0;
    transition: all 0.35s;
}

.tab-close {
    display: flex;
    justify-content: flex-end;
    padding: 1em;
    font-size: 0.75em;
    background: #2c3e50;
    cursor: pointer;
}

.tab-close:hover {
    background: #1a252f;
}

input:not([disabled]):checked+.tab-label {
    background: var(--bg-tabs-header);
}

.header_event {
    background: var(--bg-tabs-header);
}

input:not([disabled]):checked+.tab-label::after {
    transform: rotate(-90deg);
    /* transform: scaleY(-1); */
}

input:not([disabled]):checked~.tab-content_cal {
    max-height: 100vh;
}

.tab #chck1 {
    position: absolute;
    opacity: 0;
    z-index: -1;
}

.header_tabs_scanner {
    background-color: var(--bg-tabs-arbitraje);
}

.tabs_active_sca {
    background-color: var(--bg-tab-active-arbitraje);
    border-radius: 0.425rem 0.425rem 0px 0px;
    color: white;
}

.tabs_active_sca:hover {
    background-color: none;
}

.box_filters {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    box-shadow: var(--shadow-filters)
}

.border_tc {
    border: 1px solid rgba( 225, 227, 234, 1) !important;
}

.text-switch {
    color: white !important;
}