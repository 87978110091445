.kr-icon-arrow {
  top: 8px;
}

.kr-brand-selector-wrapper {
  .kr-arrow {
    top: -20px;
  }
}

.kr-icon {
  margin-left: -6px;
}

.kr-expiry.kr-size-half .kr-icon {
  margin-left: -12px;
}

.kr-inner-wrapper .kr-field-container input.kr-input-field {
  height: 6px;
}

&[kr-popin] .kr-outer-wrapper .kr-inner-wrapper {
  height: 25px;
}
