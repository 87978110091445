[data-theme="light"] {
    --bg-filtro: #f9f9f9;
    --bg-filtro-hover: #131c4d;
    --shadow-clones: 0px 5px 18px -6px rgb(32 53 90 / 50%);
    --bg-item-clon: #e1e3ea;
}

[data-theme="dark"] {
    --bg-filtro: #00B74F;
    --bg-filtro-hover: #08B05C;
    --shadow-clones: 0px 2px 12px -2px rgb(255 255 255 / 50%);
    --bg-item-clon: #323248;
}

.checks_filtros {
    width: 1.5rem !important;
    height: 1.5rem !important;
}

.rti--container {
    --rti-bg: var(--kt-content-bg-color) !important;
    // --rti-border: "#ccc";
    --rti-main: "#fff" !important;
    // --rti-radius: "0.375rem";
    --rti-tag: var(--kt-input-solid-bg) !important;
    --rti-tag-remove: "#e53e3e" !important;
    padding: .5rem;
    border: none;
}

.btn-filtro {
    font-weight: 800;
    background-color: transparent;
    color: var(--bg-filtro);
    &:hover {
        background-color: var(--bg-filtro);
        color: #181c32;
        font-weight: 800;
    }
}

.rti--tag {
    padding: 0.4rem 1rem;
    button {
        color: var(--kt-text-dark) !important;
        &:hover {
            color: #e53e3e !important;
        }
        ;
    }
}

.rti--input {
    background-color: var(--kt-input-solid-bg) !important;
    border-color: #f1f1f14c !important;
    color: var(--kt-input-solid-color) !important;
    transition: color 0.2s ease;
    border: 1px solid var(--kt-input-border-color);
    box-shadow: none !important;
    padding: 0.375rem 1rem;
    background-clip: padding-box;
    appearance: none;
    border-radius: 0.475rem;
    outline: none !important;
}

@media screen and (max-width: 992px) {
    .modal_filtros {
        width: auto !important;
        max-width: max-content;
        margin: 1.75rem;
    }
}

.content_clones {
    position: absolute;
    top: 20px;
    left: 0;
    z-index: 10;
    width: 150px;
    height: 75px;
    overflow-y: scroll;
    background-color: var(--kt-modal-bg);
    color: var(--kt-modal-color);
    border-radius: 5px;
    box-shadow: var(--shadow-clones);
}

.show_clon {
    display: none;
}

.item_clon:hover {
    background-color: var(--bg-item-clon);
}

// .icon_show:hover .content_clones,
// .icon_show:focus-within .content_clones {
//     display: initial;
// }

.text_filtro {
    font-size: 12px;
}