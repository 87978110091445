.kr-popin-modal-header {
  background-color: #e4e4e4;
  color: #333;
  height: $header-height;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;

  &.kr-secure-iframe-visible {
    height: 30px;
    border-bottom: 0;
    margin-bottom: 0;
    justify-content: space-between;
  }

  .kr-popin-modal-header-background,
  .kr-popin-modal-header-background-image {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  span {
    &.kr-popin-header {
      font-size: 12px;
      line-height: 30px;
      margin: 0;
      padding: 0;
      text-align: center;
      display: block;
      font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
      left: 0;
      top: 0;
    }

    &.kr-popin-shop-name {
      user-select: none;
      z-index: 1;
      margin-top: 50px;
      padding: 0 20px;
      left: 0;
      top: 0;
      position: relative;

      span {
        font-size: 16px;
      }
    }

    &.kr-popin-amount,
    &.kr-popin-order-id {
      display: none;
    }
  }

  i.fi-x-circle {
    cursor: pointer;
    width: 12px;
    height: 12px;
    position: absolute;
    right: 0;
    top: 0;
    margin: 4px 4px 0 0;
    z-index: 1;
  }

  &.kr-secure-iframe-visible {
    i {
      &.fi-x-circle.icon-close-popup {
        position: relative;
        opacity: 0;
        margin-top: 0;

        &.kr-visible {
          opacity: 1;
        }
      }

      &.fi-lock {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 4px;

        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  /* Header logo */
  .kr-popin-modal-header-image {
    position: absolute;
    top: -44px;
    width: 84px;
    height: 84px;

    .kr-header-logo,
    .kr-header-border-shadow,
    .kr-header-border {
      border-radius: 50%;
      position: absolute;
    }

    .kr-header-border-shadow {
      width: 84px;
      height: 84px;
      background-color: rgb(0, 0, 0, 20%);
    }

    .kr-header-border {
      width: 80px;
      height: 80px;
      background-color: #808080;
      margin-top: 2px;
      margin-left: 2px;
    }

    .kr-header-logo {
      width: 74px;
      height: 74px;
      margin-top: 5px;
      margin-left: 5px;
      background-color: #293c7a;
    }
  }
}
