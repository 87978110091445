.card_nom_header {
    background-color: var(--kt-content-bg-color) !important;
}

@media screen and (min-width: 1900px) {
    .col-2xl-4 {
        flex: 0 0 auto;
        width: 33.33333333%;
    }
}

.item_nom:hover {
    background-color: #25B56E;
    border-radius: 5px;
    color: white;
}
@media screen and (max-height: 600px) {
    .content_nomen{
        max-height: 400px;
        overflow-y: scroll;
    }
}

@media screen and (min-height: 601px) and (max-height: 850px) {
    .content_nomen{
        max-height: 550px;
        overflow-y: scroll;
    }
}