@use "sass:math";

$field-width: 231px;
$icon-width: 44px;
$help-width: 50px;
$form-width: $field-width + $help-width;
$inner-width: $field-width + $help-width - 15px;
$smartform-width: 300px;
$smartform-header-height: 68px;
$smartform-option-height: 50px;
$smartform-compact-width: 425px;
$color-light: #fff;
$color-grey: #464a4c;
$color-disabled: #ebebe4;
$color-grey-font: #717171;
$color-grey-border: #b1b1b1;
$color-grey-placeholder: #999;
$color-icon-error: #d9534f;
$color-error: #d9534f;
$color-primary: #293c7a;
$color-primary-light: #3952a5;
$circle-size: 30px;
$check-height: math.div($circle-size, 2);
$check-width: math.div($check-height, 2);
$check-left: math.div($circle-size, 6);
$check-thickness: math.div($circle-size, 10);
$button-width: 232px;
$button-height: 46px;
$shop-name-width: 245px;
