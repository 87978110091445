.kr-methods-list .kr-form-error {
  position: relative;
  margin: 10px 0 5px;
  width: 100%;
  font-size: 14px;
}

.kr-smartform-modal {
  .kr-methods-list .kr-form-error {
    margin: 10px 15px 5px;
    width: calc(100% - 30px);
  }
}
